import { colorConstants } from 'data/constants';
import { FC, SVGProps } from 'react';

interface IQuotationMark extends SVGProps<SVGSVGElement> {
  color?: typeof colorConstants.black | typeof colorConstants.gray;
  small?: boolean;
}

const QuotationMark: FC<IQuotationMark> = ({
  color = colorConstants.gray,
  small = true,
}) => {
  return (
    <div className="quotation-mark">
      {small ? (
        <svg
          width="40"
          height="27"
          viewBox="0 0 40 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.115458 16.8773C0.088292 17.1575 0.0743931 17.4416 0.0743931 17.7289C0.0743927 22.5834 4.04313 26.5188 8.93881 26.5188C13.8345 26.5188 17.8032 22.5834 17.8032 17.7289C17.8032 12.8743 13.8345 8.93895 8.93881 8.93895C8.86099 8.93895 8.7834 8.93995 8.70607 8.94192L8.78993 8.86446L17.5054 4.19356e-05L15.0908 0.292709C6.60543 1.32124 0.170921 8.43815 2.74091e-06 16.984L0.115458 16.8773Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.3122 16.8773C22.2851 17.1575 22.2712 17.4416 22.2712 17.7289C22.2712 22.5834 26.2399 26.5188 31.1356 26.5188C36.0313 26.5188 40 22.5834 40 17.7289C40 12.8743 36.0313 8.93895 31.1356 8.93895C31.0578 8.93895 30.9802 8.93994 30.9029 8.94192L30.9867 8.86446L39.7022 4.19356e-05L37.2876 0.292709C28.8022 1.32124 22.3677 8.43815 22.1968 16.984L22.3122 16.8773Z"
            fill={color}
          />
        </svg>
      ) : (
        <svg
          width="50"
          height="34"
          viewBox="0 0 50 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.144202 21.0966C0.110244 21.4468 0.0928698 21.8019 0.0928698 22.161C0.0928692 28.2292 5.05379 33.1484 11.1734 33.1484C17.293 33.1484 22.2539 28.2292 22.2539 22.161C22.2539 16.0929 17.293 11.1736 11.1734 11.1736C11.0761 11.1736 10.9791 11.1749 10.8825 11.1773L10.9873 11.0805L21.8816 -1.14766e-05L18.8634 0.365825C8.25667 1.65149 0.21353 10.5476 -0.000117214 21.2299L0.144202 21.0966Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.8903 21.0966C27.8563 21.4469 27.839 21.8019 27.839 22.161C27.839 28.2292 32.7999 33.1484 38.9195 33.1484C45.0391 33.1484 50 28.2292 50 22.161C50 16.0929 45.0391 11.1736 38.9195 11.1736C38.8222 11.1736 38.7252 11.1749 38.6286 11.1773L38.7334 11.0805L49.6277 -1.14766e-05L46.6095 0.365825C36.0028 1.65149 27.9596 10.5476 27.746 21.2299L27.8903 21.0966Z"
            fill={color}
          />
        </svg>
      )}
    </div>
  );
};

export default QuotationMark;
