import { FC, SVGProps } from 'react';

const ArrowRight: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.77817 8.07107L1.70711 1L1 1.70711L7.36396 8.07107L1 14.435L1.70711 15.1421L8.77817 8.07107Z"
        fill="#191919"
      />
    </svg>
  );
};

export default ArrowRight;
