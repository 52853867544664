import { FC, SVGProps } from 'react';

interface IHeroTitleRightDecorator extends SVGProps<SVGSVGElement> {

}

const HeroTitleRightDecorator: FC<IHeroTitleRightDecorator> = () => {
  return (
    <svg width="27" height="119" viewBox="0 0 27 119" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="25" y1="116.5" x2="4" y2="116.5" stroke="#BAA57E"/>
      <line x1="24.5" y1="117" x2="24.5" y2="-2.18557e-08" stroke="#BAA57E"/>
      <circle cx="2.5" cy="116.5" r="2.5" fill="#BAA57E"/>
      <circle cx="24.5" cy="2.5" r="2.5" fill="#BAA57E"/>
    </svg>
  )
}

export default HeroTitleRightDecorator
