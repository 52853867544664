import { FC, SVGProps } from 'react';

interface IHeroTitleLeftDecorator extends SVGProps<SVGSVGElement> {

}

const HeroTitleLeftDecorator: FC<IHeroTitleLeftDecorator> = () => {
  return (
    <svg width="27" height="119" viewBox="0 0 27 119" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="2" y1="2.5" x2="23" y2="2.5" stroke="#BAA57E"/>
      <line x1="2.5" y1="2" x2="2.49998" y2="119" stroke="#BAA57E"/>
      <circle cx="24.5" cy="2.5" r="2.5" transform="rotate(-180 24.5 2.5)" fill="#BAA57E"/>
      <circle cx="2.5" cy="116.5" r="2.5" transform="rotate(-180 2.5 116.5)" fill="#BAA57E"/>
    </svg>
  )
}

export default HeroTitleLeftDecorator
