import { FC, SVGProps } from 'react';

const ArrowLeft: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 8.07107L8.07107 15.1421L8.77817 14.435L2.41421 8.07107L8.77817 1.70711L8.07107 1L1 8.07107Z"
        fill="#191919"
      />
    </svg>
  );
};

export default ArrowLeft;
